<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加活动</en-button>
    </template>
    <tabs-maintain
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MALGPPCSTAT']) }"
      :props="{ value: 'code', label: 'message' }"
      v-model="tabs.status"
      :method="table.get"
    >
    </tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              <en-button @click="table.code.click(row)" :params="row" text v-if="['A', 'I', 'E'].includes(row.status.code)"> 查看二维码</en-button>
              <button-delete :method="table.operation.delete.click" :params="row" v-if="row.status.code === 'P'"> 删除</button-delete>
            </template>
          </en-table-column>
          <en-table-column label="活动编号" prop="serialNo">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              <en-button type="primary" text @click="table.serialNo.click(row)" :params="row">{{ row.serialNo }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="活动名称" prop="name"></en-table-column>
          <en-table-column label="二维码总数" prop="qrcodeCount"></en-table-column>
          <en-table-column label="红包总额" prop="amountPerQrcode">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              {{ formatMoney(row.amountPerQrcode) }}
            </template>
          </en-table-column>
          <en-table-column label="已扫码人数" prop="scannedCount"></en-table-column>
          <en-table-column label="已发红包数" prop="receivedCount"></en-table-column>
          <en-table-column label="已发红包金额" prop="receivedAmount">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              {{ formatMoney(row.receivedAmount) }}
            </template>
          </en-table-column>
          <en-table-column label="活动创建日期" prop="preparedDatetime">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              {{ formatDate(row.preparedDatetime) }}
            </template>
          </en-table-column>
          <en-table-column label="创建人" prop="preparedBy"></en-table-column> </en-table
      ></template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '活动'">
    <flex-box>
      <template #default="{ height }">
        <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
          <en-form-item label="活动编号" prop="serialNo">
            <en-input v-model="detail.form.data.serialNo" disabled="formDataDisabled"></en-input>
          </en-form-item>

          <en-form-item label="红包活动总金额">
            <en-input-number
              :model-value="calculator.mul(detail.form.data.qrcodeCount, detail.form.data.amountPerQrcode)"
              class="w-full"
              disabled
            ></en-input-number>
          </en-form-item>
          <en-form-item label="活动名称" prop="name">
            <en-input
              v-model="detail.form.data.name"
              :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
            ></en-input>
          </en-form-item>
          <en-form-item label="二维码总数" prop="qrcodeCount">
            <en-input-number
              v-model="detail.form.data.qrcodeCount"
              class="w-full"
              :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
            ></en-input-number> </en-form-item
          ><en-tooltip effect="dark" content="同一个二维码红包数量必须大于或等于1" placement="top">
            <en-form-item label="单个二维码红包个数" prop="countPerQrcode">
              <div style="margin-left: 140px; margin-top: -40px">
                <img src="../../assets/question.png" style="width: 16px; height: 16px" />
              </div>
              <en-input-number
                v-model="detail.form.data.countPerQrcode"
                class="w-full"
                :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
              ></en-input-number>
            </en-form-item>
          </en-tooltip>
          <en-tooltip effect="dark" content="同一个二维码红包数量必须大于或等于1" placement="top">
            <en-form-item label="单个二维码红包总金额" prop="amountPerQrcode">
              <div style="margin-left: 155px; margin-top: -40px">
                <img src="../../assets/question.png" style="width: 16px; height: 16px" />
              </div>
              <en-input-number
                v-model="detail.form.data.amountPerQrcode"
                class="w-full"
                :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
              ></en-input-number>
            </en-form-item>
          </en-tooltip>

          <en-tooltip effect="dark" content="同一个微信每天领取红包数量不得超过10个" placement="top">
            <en-form-item label="单人每天领取次数" prop="maxCountPerCustomer">
              <div style="margin-left: 130px; margin-top: -40px">
                <img src="../../assets/question.png" style="width: 16px; height: 16px" />
              </div>
              <en-input-number
                v-model="detail.form.data.maxCountPerCustomer"
                class="w-full"
                :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
              ></en-input-number>
            </en-form-item>
          </en-tooltip>

          <en-tooltip effect="dark" content="单个红包最低金额不少于1元" placement="top">
            <en-form-item label="单个红包最小金额" prop="minAmount">
              <div style="margin-left: 130px; margin-top: -40px">
                <img src="../../assets/question.png" style="width: 16px; height: 16px" />
              </div>
              <en-input-number
                v-model="detail.form.data.minAmount"
                class="w-full"
                :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
              ></en-input-number>
            </en-form-item>
          </en-tooltip>

          <en-tooltip effect="dark" content="单个红包最高金额不得大于1元" placement="top">
            <en-form-item label="单个红包最大金额" prop="maxAmount">
              <div style="margin-left: 130px; margin-top: -40px">
                <img src="../../assets/question.png" style="width: 16px; height: 16px" />
              </div>
              <en-input-number
                v-model="detail.form.data.maxAmount"
                class="w-full"
                :disabled="detail.form.data.status.code === 'E' || detail.form.data.status.code === 'A'"
              ></en-input-number>
            </en-form-item>
          </en-tooltip>
          <en-form-item label="活动有效期">
            <en-date-picker
              v-model:start="detail.form.data.startDate"
              v-model:end="detail.form.data.endDate"
              type="daterange"
              value-format="YYYY-MM-DD"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="是否开启精准定位" prop="locationRequired">
            <en-radio label="Y" v-model="detail.form.data.locationRequired.code">是</en-radio>
            <en-radio label="N" v-model="detail.form.data.locationRequired.code">否</en-radio>
          </en-form-item>
          <en-form-item label="备注" prop="comment">
            <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
          </en-form-item>
        </en-form>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
      <en-button @click="detail.footer.downline.click" type="primary" v-if="['I', 'A'].includes(detail.form.data.status.code)">下线活动</en-button>
      <en-button @click="detail.footer.pass.click" type="primary" v-if="detail.form.data.status.code === 'P'">通过审核</en-button>
    </template>
  </en-drawer>
  <en-drawer v-model="code.visible" title="查看红包活动详情" size="60%">
    <flex-box>
      <template #default="{ height }">
        <en-table :data="code.table.data" :loading="code.table.loading" :method="code.table.get">
          <en-table-column label="二维码编号" prop="qrcode"></en-table-column>
          <en-table-column label="扫码次数" prop="scannedCount"></en-table-column>
          <en-table-column label="已领红包数量" prop="receivedCount"></en-table-column>
          <en-table-column label="已领红包金额" prop="receivedAmount"></en-table-column>
          <en-table-column label="首次领红包时间" prop="firstReceivedDatetime">
            <template #default="{ row }: { row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto'] }">
              {{ formatDate(row.firstReceivedDatetime) }}
            </template>
          </en-table-column>
          <en-table-column label="已发红包数" prop="receivedCount"></en-table-column>
        </en-table>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="code.footer.export.click">导出</en-button>
      <en-button @click="code.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
type CustomerDto = EnocloudMallDefinitions['MallMarketingPromotionQueryDto']
export default factory({
  props: {
    modelValue: {
      type: Object as PropType<CustomerDto>,
      require: true
    },
    loading: Boolean as PropType<boolean>,
    rules: Object
  },
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        status: ''
      },
      form: {
        is: 'form',
        data: {},
        async change<P extends keyof CustomerDto>(prop: P, value: any) {
          if (this.modelValue) this.modelValue[prop] = value
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/marketing/promotion/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto']) {
                this.code.form.data.id = row.id
                await this.code.table.delete()
                return this.table.get()
              }
            }
          },
          serialNo: {
            async click(row: EnocloudMallDefinitions['MallMarketingPromotionQueryDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              await this.detail.form.get()
              this.detail.visible = true
            }
          },
          code: {
            async click(row: EnocloudMallDefinitions['MallMarketingPromotionQrcodeDto']) {
              this.code.form.data.id = row.id
              await this.code.table.get()
              this.code.visible = true
            }
          }
        }
      },
      code: {
        visible: false,
        children: {
          form: {
            data: {
              id: '',
              qrcode: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/mall/marketing/promotion/:mallMarketingPromotionId/qrcode',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = [this.code.form.data.id]
                }
              },
              export: {
                action: 'GET /enocloud/mall/marketing/promotion/:mallMarketingPromotionId/qrcode/export',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = [this.code.form.data.id]
                }
              },
              delete: {
                action: 'DELETE /enocloud/mall/marketing/promotion/:mallMarketingPromotionId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.code.form.data.id]
                }
              }
            }
          },

          footer: {
            export: {
              async click() {
                await this.code.table.export()
                this.code.table.get()
                this.store.openDownload()
              }
            },
            cancel: {
              click() {
                this.code.visible = false
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            data: {
              serialNo: '',
              locationRequired: {},
              status: {
                code: ''
              }
            },
            is: 'form',
            ajax: {
              get: {
                action: 'GET /enocloud/mall/marketing/promotion/:mallMarketingPromotionId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/mall/marketing/promotion',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/mall/marketing/promotion',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              downline: {
                action: 'POST /enocloud/mall/marketing/promotion/:mallMarketingPromotionId/close',
                loading: true,
                validate: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              pass: {
                action: 'POST /enocloud/mall/marketing/promotion/:mallMarketingPromotionId/audit',
                loading: true,
                validate: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入活动名称' }],
              qrcodeCount: [{ required: true, message: '请输入二维码总数' }],
              countPerQrcode: [{ required: true, message: '请输入单个二维码红包个数' }],
              amountPerQrcode: [{ required: true, message: '请输入二维码总数' }],
              maxCountPerCustomer: [{ required: true, message: '请输入单人每天领取次数' }],
              minAmount: [{ required: true, message: '请输入单个红包最小金额' }],
              maxAmount: [{ required: true, message: '请输入单个红包最大金额' }],
              endDate: [{ required: true, message: '请输入活动有效期' }]
            },
            amount: {
              change() {
                return this.detail.foem.data.amountPerQrcode * this.detail.foem.data.qrcodeCount
              }
            },
            computed() {
              return calculator.mul(this.detail.form.amountPerQrcode, this.detail.form.qrcodeCount)
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            pass: {
              async click() {
                await this.detail.form.pass().then(() => (this.detail.visible = false))
              }
            },
            downline: {
              async click() {
                await this.detail.form.downline().then(() => (this.detail.visible = false))
                return this.table.get()
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  },
  computed: {
    formDataDisabled() {
      let code = this.form.data.status.code
      return code !== 'P' && code !== ''
    }
  }
})
</script>
